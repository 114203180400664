<template>
  <div>
    <h1>Уведомления</h1>
    <p>
      Компонент "Уведомления" в нашем фреймворке предназначен для улучшения
      взаимодействия пользователей с приложением. Он предоставляет удобный и
      визуально привлекательный способ информировать пользователей о различных
      событиях, изменениях состояния или предоставлении обратной связи.
    </p>
    <p>
      Уведомления могут быть использованы для множества целей, таких как
      подтверждение успешных действий, предупреждения о возможных ошибках,
      информирование о новых сообщениях или обновлениях и многое другое.
    </p>
    <div class="example-area">
      <div class="d-flex gap-16">
        <vs-button shadow icon @click="openNotification(null, null)">
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button flat icon @click="openNotification(null, 'primary')">
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button
          flat
          success
          icon
          @click="openNotification('top-right', 'success')"
        >
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button
          flat
          danger
          icon
          @click="openNotification('top-left', 'danger')"
        >
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button
          flat
          warn
          icon
          @click="openNotification('bottom-left', 'warn')"
        >
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button
          flat
          dark
          icon
          @click="openNotification('bottom-center', 'dark')"
        >
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button
          flat
          color="#7d33ff"
          icon
          @click="openNotification('top-center', '#7d33ff')"
        >
          <i class="material-icons">notifications</i>
        </vs-button>
        <vs-button
          flat
          color="rgb(59,222,200)"
          icon
          @click="openNotification(null, 'rgb(59,222,200)')"
        >
          <i class="material-icons">notifications</i>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openNotification(position = null, color) {
      this.$vs.notification({
        color,
        position,
        title: "Документация Zenith",
        text: "Эти документы относятся к последней версии Zenith (1.0+)",
      });
    },
  },
};
</script>
<style></style>
