<template>
  <div id="app">
    <AppHeader :isOpen="isOpen" @toggle-menu="toggleMenu" />
    <div class="main">
      <SideMenu :isOpen="isOpen" @update:isOpen="updateIsOpen" />
      <MainContent />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import SideMenu from "./components/SideMenu.vue";
import MainContent from "./components/MainContent.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    SideMenu,
    MainContent,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    updateIsOpen(value) {
      this.isOpen = value;
    },
  },
};
</script>

<style>
:root {
  --vs-gray-2: 30, 32, 35 !important;
  --vs-gray-3: 44, 62, 80 !important;
  --vs-gray-1: 30, 32, 35 !important;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: #2c3e50;
}

#app {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.main {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
}

h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 40px;
  padding: 0;
  color: #fff;
}

a {
  color: inherit;
  text-decoration: underline;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gap-16 {
  gap: 16px;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.example-area {
  margin-bottom: 3rem;
  padding: 3rem 1.5rem;
  border-radius: 16px;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s linear;

  gap: 16px;
}

.example-area.black:hover {
  background: #000;
}

.example-area:hover {
  background-color: #2c3e50;
}

.with-description {
  position: relative;
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
}

.with-description .description {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 14px;
  color: #6f6e6e;
}

.router-link-exact-active {
  padding-left: 2rem !important;
}

@media screen and (max-width: 1166px) {
  .description {
    display: none;
  }
}
</style>
