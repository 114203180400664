<template>
  <div class="header">
    <header class="align-center">
      <div class="d-flex gap-16 align-center no-wrap">
        <div class="side-menu__button">
          <vs-button @click="$emit('toggle-menu')">
            <i class="material-icons">menu</i>
          </vs-button>
        </div>
        <a style="color: #fff" href="/">logoZenith</a>
      </div>
      <div>main-info</div>
      <div class="desktop">sub-info</div>
    </header>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    isOpen: Boolean,
  },
};
</script>

<style scoped>
.header {
  background-color: #2c3e50;
  color: #fff;
  padding: 2rem;
}
header {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.side-menu__button {
  display: none;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1166px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .side-menu__button {
    display: block;
  }
}
</style>
