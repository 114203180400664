import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuesax from "vuesax";

// Импортируем стили Vuesax
import "vuesax/dist/vuesax.css";
// Импортируем стили Material Icons
import "material-icons/iconfont/material-icons.css";

Vue.config.productionTip = false;

// Используем Vuesax
Vue.use(Vuesax);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
