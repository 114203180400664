<template>
  <div>
    <h1>Кнопки</h1>
    <p>
      Кнопки являются фундаментальной частью любого проекта: <br />с помощью
      Zenith вы можете добавить отличную кнопку с помощью одной строки кода.
    </p>
    <p>
      Кнопки имеют все состояния такие как "активное", "фокус", "наведение",
      чтобы упростить их реализацию и ускорить работу
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16">
          <vs-button :active="active === 0" @click="active = 0">
            Активная
          </vs-button>
          <vs-button :active="active === 1" @click="active = 1">
            Стандартная
          </vs-button>
          <vs-button disabled>Отключенная</vs-button>
        </div>
      </template>
    </div>
    <p>
      Вы можете полностью изменить стиль кнопки на любой подходящий Вашему
      проекту
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Стиль</div>
          <vs-button flat>Плоская</vs-button>
          <vs-button border>Граница</vs-button>
          <vs-button gradient>Градиент</vs-button>
          <vs-button relief>Рельефная</vs-button>
          <vs-button transparent>Прозрачная</vs-button>
        </div>
      </template>
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Цвет</div>
          <vs-button success flat>Успешно</vs-button>
          <vs-button danger border>Ошибка</vs-button>
          <vs-button warn gradient>Внимание</vs-button>
          <vs-button color="#7d33ff" relief>Палитра HEX</vs-button>
          <vs-button color="rgb(59,222,200)" transparent>Палитра RGB</vs-button>
        </div>
      </template>
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Иконки</div>
          <vs-button icon>
            <i class="material-icons">settings</i>
          </vs-button>

          <vs-button icon color="success" flat>
            <i class="material-icons">dashboard</i>
          </vs-button>

          <vs-button icon color="danger" border>
            <i class="material-icons">info</i>
          </vs-button>

          <vs-button icon color="warn" gradient>
            <i class="material-icons">warning</i>
          </vs-button>

          <vs-button icon color="#7d33ff" relief>
            <i class="material-icons">shopping_cart</i>
          </vs-button>

          <vs-button icon color="rgb(59,222,200)" gradient>
            <i class="material-icons">home</i>
          </vs-button>
        </div>
      </template>
    </div>
    <p>
      Вы так же можете использовать анимацию на кнопках, чтобы сделать их более
      отзывчивыми и дружелюбными для пользователей
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Прелоадер</div>
          <vs-button loading> Домой </vs-button>

          <vs-button loading icon success flat>
            <i class="material-icons">call</i>
          </vs-button>

          <vs-button loading danger border>
            <i class="material-icons">favorite</i> Like
          </vs-button>

          <vs-button loading warn gradient> Напоминание </vs-button>

          <vs-button loading icon color="#7d33ff" relief>
            <i class="material-icons">person</i>
          </vs-button>
          <vs-button loading icon color="rgb(59,222,200)" gradient>
            <i class="material-icons">public</i>
          </vs-button>
        </div>
      </template>
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Загрузки</div>
          <vs-button upload> Домой </vs-button>

          <vs-button upload icon success flat>
            <i class="material-icons">call</i>
          </vs-button>

          <vs-button upload danger border
            ><i class="material-icons">favorite</i> Like
          </vs-button>

          <vs-button upload warn gradient> Напоминание </vs-button>

          <vs-button upload icon color="#7d33ff" relief>
            <i class="material-icons">person</i>
          </vs-button>
          <vs-button upload icon color="rgb(59,222,200)" gradient>
            <i class="material-icons">public</i>
          </vs-button>
        </div>
      </template>
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Контент</div>
          <vs-button>
            Домой
            <template #animate>
              <i class="material-icons">home</i>
            </template>
          </vs-button>

          <vs-button flat success animation-type="vertical">
            Сообщение
            <template #animate>
              <i class="material-icons">send</i> Отправить
            </template>
          </vs-button>

          <vs-button
            gradient
            style="min-width: 60px"
            warn
            animation-type="scale"
          >
            <i class="material-icons">store</i>
            <template #animate> Магазин </template>
          </vs-button>

          <vs-button icon relief danger animation-type="rotate">
            <i class="material-icons">thumb_up</i>
            <template #animate>
              <i class="material-icons">thumb_down</i>
            </template>
          </vs-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    active: 0,
  }),
};
</script>

<style>
@import "~vuesax/dist/vuesax.css";
@import "~material-icons/iconfont/material-icons.css";
</style>
