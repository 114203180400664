<template>
  <div :class="{ open: isOpen }">
    <nav :class="{ open: isOpen }">
      <ul>
        <li>
          <router-link @click.native="closeMenu" to="/buttons"
            >Кнопки</router-link
          >
        </li>
        <li>
          <router-link @click.native="closeMenu" to="/inputs"
            >Поля ввода</router-link
          >
        </li>
        <li>
          <router-link @click.native="closeMenu" to="/notifications"
            >Уведомления</router-link
          >
        </li>
        <li>
          <router-link @click.native="closeMenu" to="/dialogs"
            >Всплывающие окна</router-link
          >
        </li>
        <li>
          <router-link @click.native="closeMenu" to="/tables"
            >Таблицы</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    isOpen: Boolean,
  },
  methods: {
    closeMenu() {
      this.$emit("update:isOpen", false);
    },
  },
};
</script>

<style scoped>
nav {
  height: 100vh;
  width: 220px;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 1rem;
  z-index: 3;
  font-size: 16px;
}
nav ul {
  padding: 0;
  text-decoration: none;
  list-style: none;
}
nav a {
  position: relative;
  overflow: hidden;
  color: #ecf0f1;
  text-decoration: none;
  display: block;
  padding: 1rem 0.5rem;
  border-radius: 8px;
  margin-bottom: 0.2rem;
  transition: all 0.2s linear;
}
nav a:after {
  content: "";
  position: absolute;
  left: -4px;
  top: 0;
  width: 10px;
  height: 100%;
  background: #ecf0f1;
  border-radius: 0 20px 20px 0;
  -webkit-transform: translate(-6px);
  transform: translate(-6px);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  z-index: 60;
}
nav a.router-link-exact-active:after {
  -webkit-transform: translate(0);
  transform: translate(0);
}
nav a:hover {
  padding-left: 2rem;
  background-color: #34495e;
}
nav a.router-link-exact-active {
  padding-left: 2rem;
  background-color: #34495e;
}

@media screen and (max-width: 1166px) {
  nav {
    width: 100%;
    transition: left 0.2s linear;
    position: absolute;
    top: 0;
    left: -400px;
    z-index: 10;
    max-width: 220px;
    font-size: 14px;
  }

  nav.open {
    left: 0;
  }
}
</style>
