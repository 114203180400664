<template>
  <div>
    <h1>Поля ввода</h1>
    <p>
      Элементы ввода используются для ввода данных со стороны пользователей.
    </p>
    <p>
      Мы подготовили большое кол-во элементов ввода под разные потребности наших
      клиентов.
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16 with-description">
          <div class="description">Варианты</div>
          <vs-input
            label="ФИО"
            v-model="value"
            placeholder="Иванов Иван Иванович"
          />
          <vs-input v-model="value" placeholder="Страна" />
          <vs-input label-placeholder="Город" v-model="value" />
        </div>
      </template>
    </div>
    <p>
      Вы можете изменить цвет элемента и добавить рамку ниже со свойством цвета,
      допустимыми значениями являются основные цвета Zenith и цвета (RGB и HEX).
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16">
          <vs-input primary v-model="value" placeholder="Основной" />

          <vs-input success v-model="value" placeholder="Успешно" />

          <vs-input danger v-model="value" placeholder="Ошибка" />

          <vs-input warn v-model="value" placeholder="Внимание" />

          <vs-input dark v-model="value" placeholder="Темный" />

          <vs-input color="#7d33ff" v-model="value" placeholder="Свой цвет" />

          <vs-input
            color="rgb(59,222,200)"
            v-model="value"
            placeholder="Свой цвет"
          />
        </div>
      </template>
    </div>

    <p>
      С легкостью добавляйте иконки в поле ввода. Иконку можно добавить как в
      начале так и в конце.
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16">
          <vs-input v-model="value" placeholder="User name">
            <template #icon>
              <i class="material-icons">person</i>
            </template>
          </vs-input>

          <vs-input
            type="password"
            icon-after
            v-model="value"
            placeholder="Password"
          >
            <template #icon>
              <i class="material-icons">lock</i>
            </template>
          </vs-input>
        </div>
      </template>
    </div>
    <p>
      Вы можете настроить валидацию под полем ввода, чтобы сообщить, что
      какое-то поле обязательно к заполнению или значение неверно.
    </p>
    <div class="example-area">
      <template>
        <div class="d-flex gap-16">
          <vs-input v-model="valueEmail" placeholder="Ваша почта">
            <template v-if="valueEmail" #message-success>
              Почта верна
            </template>
          </vs-input>

          <vs-input v-model="valueRequired" placeholder="Ваше имя">
            <template v-if="valueRequired.length == 0" #message-danger>
              Обязательное поле
            </template>
          </vs-input>

          <vs-input
            type="password"
            v-model="valuePassword"
            placeholder="Ваш пароль"
          >
            <template #message-warn> Слабый пароль </template>
          </vs-input>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    value: "",
    valueEmail: "example@mail.ru",
    valuePassword: "123456",
    valueRequired: "",
  }),
};
</script>

<style>
.vs-input {
  background-color: #1e1f24 !important;
  border: 1px solid #1e1f24;
  color: #fff !important;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}
.vs-input__icon {
  background-color: #1e1f24 !important;
  border: 1px solid #1e1f24;
  color: #fff;
}
</style>
