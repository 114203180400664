<template>
  <div class="content__wrapper">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
  components: {},
  watch: {
    // Слушаем изменения маршрута
    $route() {
      this.scrollToTop();
    },
  },
  methods: {
    // Метод для прокрутки к началу
    scrollToTop() {
      this.$el.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.content__wrapper {
  position: relative;
  overflow: auto;
  flex: 1;
  padding: 4rem 30px;
  background: #1e2023;
  color: #fff;
  z-index: 3;
  border-top-left-radius: 30px;
  font-size: 14px;
  line-height: 150%;
}

.content__wrapper p {
  margin-bottom: 24px;
}

.content {
  max-width: 900px;
  margin: 0 auto;
}

@media screen and (max-width: 1166px) {
  .content__wrapper {
    border-top-right-radius: 30px;
  }
}
</style>
