<template>
  <div>
    <h1>Всплывающие окна</h1>
    <p>
      Всплывающие окна — это графические элементы на веб-странице, которые
      появляются поверх основного контента. Они могут содержать различную
      информацию, например, рекламные объявления, уведомления, формы подписки,
      опросы или специальные предложения.
    </p>
    <p>
      Это мощный инструмент, который при правильной настройке и использовании
      может значительно повысить эффективность веб-сайта и удовлетворенность
      пользователей.
    </p>
    <div class="example-area">
      <vs-button @click="active = !active">Открыть</vs-button>
      <vs-dialog blur v-model="active">
        <template #header>
          <h4 class="not-margin">Добро пожаловать в <b>Zenith</b></h4>
        </template>

        <div class="con-form">
          <vs-input v-model="email" placeholder="Ваша почта">
            <template #icon>
              <i class="material-icons">email</i>
            </template>
          </vs-input>
          <vs-input type="password" v-model="password" placeholder="Пароль">
            <template #icon>
              <i class="material-icons">lock</i>
            </template>
          </vs-input>
        </div>
        <div class="d-flex space-between align-center">
          <vs-checkbox v-model="remember">Запомнить меня</vs-checkbox>
          <a href="#">Забыли пароль?</a>
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button block> Войти </vs-button>

            <div class="new">Нет аккаунта? <a href="#">Создать новый</a></div>
          </div>
        </template>
      </vs-dialog>
    </div>
    <p>Добавьте анимацию загрузки в всплывающее окно.</p>
    <div class="example-area">
      <vs-button success flat @click="active1 = !active1"
        >Открыть с анимацией загрузки</vs-button
      >
      <vs-dialog loading v-model="active1">
        <template #header>
          <h4 class="not-margin">Добро пожаловать в <b>Zenith</b></h4>
        </template>

        <div class="con-form">
          <vs-input v-model="email" placeholder="Ваша почта">
            <template #icon>
              <i class="material-icons">email</i>
            </template>
          </vs-input>
          <vs-input type="password" v-model="password" placeholder="Пароль">
            <template #icon>
              <i class="material-icons">lock</i>
            </template>
          </vs-input>
        </div>
        <div class="d-flex space-between align-center">
          <vs-checkbox v-model="remember">Запомнить меня</vs-checkbox>
          <a href="#">Забыли пароль?</a>
        </div>

        <template #footer>
          <div class="footer-dialog">
            <vs-button block> Войти </vs-button>

            <div class="new">Нет аккаунта? <a href="#">Создать новый</a></div>
          </div>
        </template>
      </vs-dialog>
    </div>
    <p>
      В некоторых случаях Вам нужна прокрутка, поскольку в диалоговом окне
      содержится много информации. Для этого вы можете использовать свойство
      прокрутки.
    </p>
    <div class="example-area">
      <vs-button gradient warn @click="active2 = !active2"
        >Открыть с прокруткой</vs-button
      >
      <vs-dialog scroll overflow-hidden auto-width v-model="active2">
        <template #header>
          <h4 class="not-margin">Введение в <b>Zenith</b></h4>
        </template>

        <div class="con-content">
          <h4>Что такое Zenith?</h4>
          <p>
            Zenith (произносится / зенит /, как зенит) - это фреймворк
            UI-компонентов, созданный с использованием Vue.js для облегчения
            работы над проектами с уникальным и приятным стилем. Zenith создан с
            нуля и предназначен для всех типов разработчиков - от любителей
            фронтенда до бэкенд-разработчиков, которые хотят легко создать
            визуальную часть для конечных пользователей. Мы сосредоточены на
            упрощении работы программиста, предоставляя полностью готовые
            компоненты с независимой настройкой и очень простые в реализации,
            чтобы творчество было в наших руках, но при этом не забываем, что
            каждый проект уникален как визуально, так и в своей экосистеме.
            Zenith не имеет дизайн-линии, как другие фреймворки компонентов,
            основанные на Material Design. Мы считаем, что уже есть множество
            фреймворков, которые выглядят визуально и в плане UI/UX, и мы не
            хотим быть одним из них. Помимо этого, мы любим создавать и
            разрабатывать новые впечатления и удивлять вас новыми элементами или
            деталями, которые мы можем делать, будучи визуально свободными.
          </p>

          <h4>Почему Zenith?</h4>

          <p>
            Zenith - это относительно новый фреймворк с освежающим дизайном и
            последними трендами, основанный на Vue.js, что означает, что мы идем
            рука об руку с одним из самых популярных JavaScript-фреймворков в
            мире и с огромным сообществом, которое предоставит вам всю
            необходимую помощь и документацию для создания и разработки вашего
            проекта.
            <br />
            <br />
            - Zenith, в отличие от многих фреймворков, создан с нуля, и мы не
            привязаны к какой-либо дизайн-линии. Это прекрасно, так как ваш
            проект будет уникальным и очень отличаться от других.

            <br />
            <br />
            - Мы сосредоточены на быстром и легком создании проектов,
            предоставляя красивую визуальную линию, но не забывая о
            персонализации и независимости разработчика.

            <br />
            <br />
            - Zenith использует нативные переменные CSS для лучшей кастомизации
            и изменения в производстве, таких как переход на темную тему или
            изменение основного цвета всего приложения с помощью нескольких
            строк JavaScript.
            <br />
            <br />

            - Zenith - это фрейм, разработанный для создания большого
            визуального воздействия и всегда находящийся в тренде в плане
            дизайна.
            <br />
            <br />

            - Сообщество с открытым исходным кодом для создания, улучшения и
            исправления любых компонентов или функций.
            <br />
            <br />

            - Независимые компоненты, чтобы избежать импорта ненужного кода.
            <br />
            <br />

            - Документы в формате Markdown для лучшей поддержки.
            <br />
            <br />

            - и многое другое.
          </p>
        </div>
      </vs-dialog>
    </div>
    <p>
      Если Вам нужно, чтобы всплывающее окно соответствовало общему размеру
      окна, вы можете сделать это с помощью свойства полноэкранного режима.
    </p>
    <div class="example-area">
      <vs-button gradient danger @click="active3 = !active3"
        >Открыть на весь экран</vs-button
      >
      <vs-dialog overflow-hidden scroll full-screen v-model="active3">
        <template #header>
          <h4 class="not-margin">Введение в <b>Zenith</b></h4>
        </template>

        <div class="con-content">
          <h4>Что такое Zenith?</h4>
          <p>
            Zenith (произносится / зенит /, как зенит) - это фреймворк
            UI-компонентов, созданный с использованием Vue.js для облегчения
            работы над проектами с уникальным и приятным стилем. Zenith создан с
            нуля и предназначен для всех типов разработчиков - от любителей
            фронтенда до бэкенд-разработчиков, которые хотят легко создать
            визуальную часть для конечных пользователей. Мы сосредоточены на
            упрощении работы программиста, предоставляя полностью готовые
            компоненты с независимой настройкой и очень простые в реализации,
            чтобы творчество было в наших руках, но при этом не забываем, что
            каждый проект уникален как визуально, так и в своей экосистеме.
            Zenith не имеет дизайн-линии, как другие фреймворки компонентов,
            основанные на Material Design. Мы считаем, что уже есть множество
            фреймворков, которые выглядят визуально и в плане UI/UX, и мы не
            хотим быть одним из них. Помимо этого, мы любим создавать и
            разрабатывать новые впечатления и удивлять вас новыми элементами или
            деталями, которые мы можем делать, будучи визуально свободными.
          </p>

          <h4>Почему Zenith?</h4>

          <p>
            Zenith - это относительно новый фреймворк с освежающим дизайном и
            последними трендами, основанный на Vue.js, что означает, что мы идем
            рука об руку с одним из самых популярных JavaScript-фреймворков в
            мире и с огромным сообществом, которое предоставит вам всю
            необходимую помощь и документацию для создания и разработки вашего
            проекта.
            <br />
            <br />
            - Zenith, в отличие от многих фреймворков, создан с нуля, и мы не
            привязаны к какой-либо дизайн-линии. Это прекрасно, так как ваш
            проект будет уникальным и очень отличаться от других.

            <br />
            <br />
            - Мы сосредоточены на быстром и легком создании проектов,
            предоставляя красивую визуальную линию, но не забывая о
            персонализации и независимости разработчика.

            <br />
            <br />
            - Zenith использует нативные переменные CSS для лучшей кастомизации
            и изменения в производстве, таких как переход на темную тему или
            изменение основного цвета всего приложения с помощью нескольких
            строк JavaScript.
            <br />
            <br />

            - Zenith - это фрейм, разработанный для создания большого
            визуального воздействия и всегда находящийся в тренде в плане
            дизайна.
            <br />
            <br />

            - Сообщество с открытым исходным кодом для создания, улучшения и
            исправления любых компонентов или функций.
            <br />
            <br />

            - Независимые компоненты, чтобы избежать импорта ненужного кода.
            <br />
            <br />

            - Документы в формате Markdown для лучшей поддержки.
            <br />
            <br />

            - и многое другое.
          </p>
        </div>
      </vs-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: false,
    active1: false,
    active2: false,
    active3: false,
    email: "",
    password: "",
    remember: false,
  }),
};
</script>

<style>
/* Стили */
.not-margin {
  margin: 0;
  font-weight: normal;
  padding: 10px;
}

.vs-dialog {
  background-color: #2c3e50 !important;
  color: #fff !important;
}

.vs-dialog .vs-icon-close:before,
.vs-dialog .vs-icon-close:after {
  background: #fff;
}

.vs-dialog__loading {
  background-color: rgba(44, 62, 80, 0.8) !important;
}

.con-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.con-form .vs-input-parent {
  width: 100% !important;
}

.con-form .vs-input {
  width: 100% !important;
}

.con-form .vs-input--has-icon {
  background-color: #ecf0f1;
  width: 100% !important;
}
a {
  font-size: 1rem;
  opacity: 0.7;
}

a:hover {
  opacity: 1;
}

.vs-checkbox-label {
  font-size: 1rem;
}

.vs-input-content {
  margin: 10px 0;
  width: 100%;
}

.vs-input {
  width: 100%;
}

.footer-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.new {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  font-size: 0.7rem;
}

.new a {
  color: rgba(0, 123, 255, 1) !important;
  margin-left: 6px;
}

.new a:hover {
  text-decoration: underline;
}

.vs-button {
  margin: 0;
}
</style>
