import Vue from "vue";
import Router from "vue-router";
import ButtonsZenith from "./components/frameworkComponents/ButtonsZenith.vue";
import InputsZenith from "./components/frameworkComponents/InputsZenith.vue";
import NotificationZenith from "./components/frameworkComponents/NotificationZenith.vue";
import DialogZenith from "./components/frameworkComponents/DialogZenith.vue";
import TableZenith from "./components/frameworkComponents/TableZenith.vue";
import MainPage from "./components/MainPage.vue";

Vue.use(Router);

export default new Router({
  mode: "history", // используем history mode для красивых URL без #
  routes: [
    {
      path: "/",
      name: "MainPage",
      component: MainPage,
    },
    {
      path: "/buttons",
      name: "Buttons",
      component: ButtonsZenith,
    },
    {
      path: "/inputs",
      name: "Input",
      component: InputsZenith,
    },
    {
      path: "/notifications",
      name: "Notification",
      component: NotificationZenith,
    },
    {
      path: "/dialogs",
      name: "Dialog",
      component: DialogZenith,
    },
    {
      path: "/tables",
      name: "Table",
      component: TableZenith,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
